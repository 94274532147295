import React from "react";
import "./Card.css";


function Card(props) {
  return (
    <div
      className={"Card " + props.size}
      data-scroll
      data-scroll-speed={props.speed}
      data-immerser-layer
    >
      <a href={props.project_page} className="project-link">
        {/* <img src={props.image} alt="" /> */}
        {props.type ? (
          <img src={props.image} />
        ) : (
          <video
            width={props.mobileScreenSize ? props.image[1] : props.image[2]}
            src={props.image[0]}
            source="video/avi"
            autoPlay
            loop
          />
        )}
      </a>
      <div className="card-title">
        <h2 id={props.title_id}>{props.title}</h2>
      </div>
    </div>
  );
}

export default Card;
